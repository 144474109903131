import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
        h1: {
            fontSize: 98,
            fontWeight: 'light',
            letterSpacing: '-1.5px',
        },
        h2: {
            fontSize: 61,
            fontWeight: 'light',
            letterSpacing: '-0.5px',
        },
        h3: {
            fontSize: 49,
            fontWeight: 'normal',
            letterSpacing: 0,
        },
        h4: {
            fontSize: 35,
            fontWeight: 'bold',
            letterSpacing: '0.25px',
        },
        h5: {
            fontSize: 24,
            fontWeight: 'normal',
            letterSpacing: 0,
        },
        h6: {
            fontSize: 20,
            fontWeight: 'medium',
            letterSpacing: '0.15px',
        },
        body1: {
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: '0.5px',
            color: 'inherit',
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: '0.25px',
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 'normal',
            letterSpacing: '0.15px',
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 'normal',
            letterSpacing: '0.1px',
        },
        button: {
            fontSize: 14,
            fontWeight: 'medium',
            letterSpacing: '1.25px',
        },
        overline: {
            fontSize: 10,
            fontWeight: 'normal',
            letterSpacing: '1.5px',
        },
        caption: {
            fontSize: 12,
            fontWeight: 'normal',
            letterSpacing: '0.4px',
        },
        gutterBottom: {
            marginBottom: '2em',
        },
    },
    palette: {
        primary: {
            main: '#cf102d',
            // light: '#7b7b7b',
            // dark: '#000000',
        },
        background: {
            mainAlert: '#7b7b7b',
        },
        default: {
            main: grey[300],
            contrastText: grey[900],
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthXs: {
                    padding: '24px 16px 16px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 700,
                },
                contained: {
                    '&:disabled': {
                        backgroundColor: '#7b7b7b',
                        color: '#fff',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: '0.7em',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                selectMenu: {
                    whiteSpace: 'normal',
                },
            },
        },
    },
});

export default theme;
